import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Immortalized Deeds`}</h1>
    <h2>{`What is Immortalized Deeds and why does it look so bad?`}</h2>
    <p>{`This is a collection of NFTs that I make whenever someone did something that I appreciate that I do something back in return by either giving them something of monetary value or in deed. I will create the NFTs when something notable has been done to me, or when I was able to witness it and I would like to remember it and appreciate it. Immortalized deeds shall become a contract between me and whoever holds the NFT or as a token of appreciation from me.`}</p>
    <p>{`I am not an artist. The first NFT I have created is created without that much of a thought, I should have just uploaded a text file. But to me it matters a little, as long as its there. I will try to improve the graphic of the NFT as I improve my art skills`}</p>
    <p>{`This page on my website shall be dedicated to each one of them, I will do my best to update this page and to update whatever happened to those NFTs.`}</p>
    <h2>{`Is there a rule about the NFTs`}</h2>
    <ol>
      <li parentName="ol">{`I personally will never sell the NFTs.`}</li>
      <li parentName="ol">{`I will try to transfer the NFT to the person who did the deed.`}</li>
      <li parentName="ol">{`If they rejected the idea I will keep the NFT, They can redeem the NFT anytime, If there is a promise written to it, I will still fulfill them at the date I promised, its a promise after all.`}</li>
      <li parentName="ol">{`If they redeem the NFT, they can do whatever they want with the NFT, but they can also return it to me for the promise I have put there. I will give back the NFT again and edit the NFT that the promise has been fulfilled.`}</li>
      <li parentName="ol">{`If the NFT has a specific fulfillment date and the person decided not to return it to me. The fulfillment of the promise shall be talked about in person.`}</li>
      <li parentName="ol">{`Whatever happen to the promise, the NFT's description will be modified to reflect it if I still can, otherwise it will be reflected on this page.`}</li>
      <li parentName="ol">{`I will take a 1% commission on the NFT when the holder decides to sell it. This will change in the future but it will never be more than 1%`}</li>
    </ol>
    <h2>{`Deeds Story`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Token`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Page Link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`"A tip of try to use less because on my writing because I use too many because."`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/40542310856415094363189399722462681283017298973556230436217834251596484050945"
            }}>{`Link`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Being written`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Update`}</h2>
    <p>{`The project is dead. For now...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      